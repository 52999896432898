@font-face {
  font-family: 'mandator';
  src: url('../fonts/mandator.ttf'); 
  src: url('../fonts/mandator.ttf')  format('truetype');
}

body{
    font-family: 'calibri', Open Sans, Arial, sans-serif;
	.row{
		margin-right:0px;
		margin-left:0px;
	}
}
.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  background:#fff;
  transition: all 0.3s;
}
.max-width-container{
	max-width:1280px;
	margin:0 auto;
}
.all-header{
  z-index:1009;
  position: fixed;
  top: 0;
  width: 100%;
  background:#fff;
  box-shadow: 0px 1px 2px #666;
  -webkit-box-shadow: 0px 1px 2px #666;
  -moz-box-shadow: 0px 1px 2px #666;
  
}
	.sections-title{
		text-align:center;
		margin-bottom:50px;
		color:#222;
		font-weight:600;
		font-size:40px;
		color:#DE201C;
		font-family: 'Arial';
	}
.header-primary{
	padding:10px;
	background:#222;
	.primary-header-content{
		font-size:13px;
		color:#d5d5d5;
		text-align:right;
		.max-width-container{
			padding-right:30px;
			padding-left:30px;
		}
		.glyphicon{
			color:#DE201C;
			margin-right:5px;
		}
		a{
			font-size:13px;
			color:#d5d5d5;
			text-decoration:none;
			
			&:hover{
				color:#fff;
				text-decoration:undeline;
			}
		}
	}
	.devider-pipe{
		color:#fff;
		font-size:16px;
		margin-left:7px;
		margin-right:7px;
	}
}
.header-row{
	max-width:1280px;
	margin:0px auto;
	.logo-sec{
		padding: 15px 0px;
		.logo-img{
			width:175px;
			margin-left:0px;
		}
	}
	.menu-item-sec{
		text-align:right;
		.menu-items{
			padding:0px;
			margin:0px;
			font-size:0px;
			.menu-item{
				display:inline-block;
				.menu-item-link{
					font-size:18px;
					text-decoration:none;
					display:block;
					padding:25px 15px;
					background:#fff;
					color:#222;
					transition: all 0.3s;
					&:hover{
						text-decoration:none;
						background:#DE201C;
						color:#fff;
						transition: all 0.3s;
					}
					
				}
				.menu-item-link-active{
					background:#DE201C;
					color:#fff;
				}
			}
		}
		
		
	}
}
.primary-banner{
	margin-top: 115px;
	overflow:hidden;
	.banner-img{
		width:100%;
		max-height:500px;
		height:500px;
	}
	.carousel-caption{
		text-align:left;
		bottom:180px;
		left:5%;
		
	
		h1{
			color:#ff8741;
			font-family: 'mandator';
			font-weight:600;
			font-size:36px;
		}
		p{
			font-family:calibri;
			font-size:22px;
			color:#e9e9e9;
		}
	}

}
.aboutunibuzz{
	background:#DE231C;
	padding:70px 0px 70px 0px;
	color:#fff;
	.reltv{
	h1{
		margin-top:0px;
	}
		position:relative;
			.readmore{
			position:absolute;
			right:30px;
			bottom:0px;
			font-size:20px;
			background:#DE231C;
			padding:10px 20px;
			color:#fff;
			border:1px dashed #fff;
			text-decoration:none;
			transition: all 0.3s;
			&:hover{
				background:#b7241f;
				transition: all 0.3s;
				text-decoration:none;
			}
		}
	}
}
.maincats{
	margin:70px 0px;

	.about h2{
		font-size: 90px;
		color: #42b3e5;
		margin-top: 50px;
	}
	.about h4{
		font-size: 24px;
		color: #737373;
		margin-bottom: 45px;
	}
	.about-img img{
		width: 100%;
		height:200px;
	}
	.single-about-detail{
		position: relative;
	}
	.pentagon-text h1{
		
		font-size: 40px;
		color: #383838;
		margin-top: 0;
		transition: all .7s ease 0s;
		-webkit-transition: all .7s ease 0s;
		-moz-transition: all .7s ease 0s;
		-o-transition: all .7s ease 0s;
		-ms-transition: all .7s ease 0s;
		
		text-align:center;
		position: absolute;
		  left: 12%;
		  width: 50px;
		  top:12px;
		  
	}
	.pentagon-text {
	  width: 70px;
	  height: 70px;
	  border-radius:50%;
	  background: #fff;
	  position: relative;
	   top: -30px;
	  left: 40%;
	}

	.about-details{
		padding-top:30px;
		background: #222;
		border-top: 2px solid #fff;
		transition: all .7s ease 0s;
		-webkit-transition: all .7s ease 0s;
		-moz-transition: all .7s ease 0s;
		-o-transition: all .7s ease 0s;
		-ms-transition: all .7s ease 0s;
		height:230px;
	}
	.about-details h3{
		font-size: 26px;
		color: #fff;
		margin-top: 0px;
		text-align:center;
	}
	.about-details p{
		font-size: 13px;
		color: #fff;
		font-family: 'Open Sans', sans-serif;
		padding: 0 25px;
		padding-bottom: 30px;
		text-align:center;
	}
	.single-about-detail:hover .about-details{
		background: #DE231C;
		cursor:pointer;
	}
	.single-about-detail:hover .pentagon-text h1{
		color: #DE231C;
		cursor:pointer;
	}

}


.all-services{
	padding-bottom: 60px;
	margin-top:70px;
	
	.single-member{
		margin: 0;
		padding: 0;
	}
	.person{
	overflow:hidden;
	img{
		width: 100%;
		height:165px;
		transition: all .7s ease 0s;
		-webkit-transition: all .7s ease 0s;
		-moz-transition: all .7s ease 0s;
		-o-transition: all .7s ease 0s;
		-ms-transition: all .7s ease 0s;
		transition: all .7s ease 0s;
	}
	}
	.person-detail{
		padding:10px;
		background: #DE231C;
		position: relative;
		height: 165px;
		transition: all .7s ease 0s;
		-webkit-transition: all .7s ease 0s;
		-moz-transition: all .7s ease 0s;
		-o-transition: all .7s ease 0s;
		-ms-transition: all .7s ease 0s;
		
		.techsupport-txt{
			font-size:16px;
			margin-top:20px;
		}
	}
	.arrow-top{
		position: absolute;
		width: 20px;
		height: 20px;
		background: #DE231C;
		transform:rotate(45deg);
		-webkit-transform:rotate(45deg);
		-moz-transform:rotate(45deg);
		-o-transform:rotate(45deg);
		-ms-transform:rotate(45deg);
		bottom: -10px;
		left: 46%;
		transition: all .7s ease 0s;
		-webkit-transition: all .7s ease 0s;
		-moz-transition: all .7s ease 0s;
		-o-transition: all .7s ease 0s;
		-ms-transition: all .7s ease 0s;
		z-index:999;
	}
	.arrow-bottom{
		position: absolute;
		width: 20px;
		height: 20px;
		background: #DE231C;
		transform:rotate(45deg);
		-webkit-transform:rotate(45deg);
		-moz-transform:rotate(45deg);
		-o-transform:rotate(45deg);
		-ms-transform:rotate(45deg);
		top: -9px;
		left: 46%;
		transition: all .7s ease 0s;
		-webkit-transition: all .7s ease 0s;
		-moz-transition: all .7s ease 0s;
		-o-transition: all .7s ease 0s;
		-ms-transition: all .7s ease 0s;
		z-index:999;
	}
	.person-detail h3{
		font-size: 30px;
		color: #fff;
		
	}
	.person-detail p{
		font-size: 13px;
		color: #fff;
		font-family: 'Open Sans', sans-serif;
	}
		.single-member{
			&:hover{
				.person img{
					transform:scale(1.2);
					transition: all .7s ease 0s;
					-webkit-transition: all .7s ease 0s;
					-moz-transition: all .7s ease 0s;
					-o-transition: all .7s ease 0s;
					-ms-transition: all .7s ease 0s;
					transition: all .7s ease 0s;
				}
				.person-detail{
					background: #222;
				}
				.arrow-top{
					background: #222;
				}
				.arrow-bottom{
					background: #222;
				
				}
				
			}
		
		}
}
.gmap-heading{
	margin-left:2%;
	padding-left:5px;
}

#addressmap{
width:96%;
height:400px;
border:1px solid #999;
padding:5px;
margin:2%;
}

.inner-header{
	background:url('../images/innerheader.jpg');
	background-repeat:none;
	background-size:100% 100%;
	padding:50px 0px;
	margin-top:115px;
	.heading-txt{
		font-size:32px;
		font-weight:600;
		color:#fff;
		font-style:italic;
	}
}
.innerpage-txt{
margin-top:50px;
margin-bottom:30px;
	.img-side{
		img{
			border:3px solid #999;
		}
	}
	.content-side{
	
	}
}

	 .feedback-form p{
			
			margin-top:10px;
			margin-bottom:3px;
		}
		 .feedback-form .enquirytxtbox{
			
			border:1px solid #d5d5d5;
			border-radius:5px;
			padding:5px;
			width:200px;
			color:#000;
			outline:none;
		}
		 .feedback-form .enquiresub{
			background:#4f3019;
			color:#fff;
			padding:8px 20px;
			font-size:16px;
			border:0px;
			outline:none;
			
		}
		 .feedback-form .enquiresub:hover{
			
			background:#ff0000;
		}
		
.footer-sec{
	
	.traininglist{
		padding:40px 15px 30px 15px;
	}
	background:#222222;
	.footer-title{
		color:#FF8741;
		font-size:18px;
	}
	.all-trainings-ul{
		padding-left:15px;
		color:#fff;
		li{
			list-style:disc;
			color:#fff;
			a{
				color:#fff;
				font-size:15px;
				text-decoration:none;
				&:hover{
					color:#FF8741;
				}
			}
		}
	}
	.footer-copy{
		background:#000;
		padding:15px;
		font-size:14px;
		color:#fff;
		a{
			color:#FF8741;
			text-decoration:none;
			&:hover{
				color:#DE231C;
			}
		}
		.txt-left{
			text-align:left;
		}
		.txt-right{
			text-align:right;
		}
	}
}